import { createApp } from 'vue';
import App from './exburst-main.vue'; // Adjust the path as per your project structure
import '../tailwind.css'; // Ensure these CSS files are correctly located
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import router from '../router'; // Ensure this path points to your router configuration

const app = createApp(App);

app.use(FloatingVue, {
    themes: {
        'info': {
            '$extend': 'tooltip',
            triggers: ['click'],
            autoHide: true,
            placement: 'auto',
            autoSize: false,
            autoBoundaryMaxSize: false,
        },
    },
});

app.use(router); // Use Vue Router

app.mount('#app'); // Mount the app to the DOM element with id 'app'
